// Project variables
$body-margin-top-mobile: 52px;
$body-margin-top: 116px;
$nav-darker-grey: #e0ddd6;
$color-whitish: #f4f2ed;

// Výška jedného pruhu v navigačnom menu.
$navbar-height: 51px;
// Rýchlosť animácia zasúvania a vysúvania loga
$navbar-trans-speed: 250ms;

$header-bar-height-4r: 96px;
$header-bar-height-3r: 79px;
$header-bar-height-2r: 57px;
$header-bar-height-1r: 40px;

// Bootstrap variables
$font-family-sans-serif: 'PT Sans Caption', sans-serif;
$font-size-h1: 26px;
$font-size-h2: 30px;
$brand-primary: #da1a32;
$text-color: #3f3a35;
$navbar-inverse-bg: #2d2b27;
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: #fff;
$headings-color: $text-color;

:root {
	--space-1: 5px;
	--gray: #999;
}
